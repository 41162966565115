<template>
    <footer class="footer">
        <div class="footer__bg">
            <div class="footer__container container grid">
                <div>
                    <h1 class="footer__title">Fandy Ahmad Irianto</h1>
                    <span class="footer__subtitle">Software Engineer</span>
                </div>

                <ul class="footer__links">
                    <li>
                        <a href="#services" class="footer_link">Services</a>
                    </li>
                    <li>
                        <a href="#portfolio" class="footer_link">Portofolio</a>
                    </li>
                    <li>
                        <a href="#contact" class="footer_link">Contactme</a>
                    </li>
                </ul>

                <div class="footer__socials">
                    <!-- <a href="https://www.facebook.com/HilmanFirdd" class="footer__social" target="__blank">
                        <i class="uil uil-facebook-f"></i>
                    </a> -->
                    <a href="https://github.com/thefvnday" class="footer__social" target="__blank">
                                <i class="uil uil-github-alt"></i>
                            </a>

                    <a href="https://www.instagram.com/fandy_irianto" class="footer__social" target="__blank">
                        <i class="uil uil-instagram"></i>
                    </a>

                    <a href="https://twitter.com/fandyirianto" class="footer__social" target="__blank">
                        <i class="uil uil-twitter-alt"></i>
                    </a>
                </div>
            </div>

            <p class="footer__copy">&#169; FVNDAY. All right reserved</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>